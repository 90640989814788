<template>
	<div class="main-content">
		<div class="row mt-4">
			<div class="col-12">
				<trip-history
					trip-type="completed"
					:request-function="(params) => fetchTrips(params)"
					:show-trip-date="false"
					:show-actions="false"
					></trip-history>
			</div>
		</div>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import TripHistory from '@/components/modules/trips/TripHistory'
export default {
  components: {
    TripHistory
  },
  methods: {
    async fetchTrips (params) {
      this.params = params
      const limit = params ? params.limit : 10
      const page = params ? params.page : 1

      const baseUrl = '/v1/trips/completed'
      let url = `${baseUrl}?limit=${limit}&page=${page}&metadata=true&`
      // let url = `${baseUrl}?limit=${limit}&page=${page}&metadata=true&sort[start_trip]=ascd&`
      if (params?.from || params?.to) {
        if (params?.from) {
          url += `from=${params.from}&`
        }
        if (params?.to) {
          url += `to=${params.to}`
        }
      }

      if (params?.search) {
        url += `&search=${params.search}`
      }
      const result = await this.axios.get(url)
      const data = result.data.data
      return {
        data: data,
        meta_data : result.data?.metadata,
      }
    }
  }
}
</script>
